const ACCESS_TOKEN = 'Coffcode.ControlPanel.AccessToken';
const USER = 'Coffcode.ControlPanel.User';

const getToken = () => localStorage.getItem(ACCESS_TOKEN);
const setToken = (token) => localStorage.setItem(ACCESS_TOKEN, token);
const cleanToken = () => localStorage.removeItem(ACCESS_TOKEN);

const getUser = () => {
  let user = localStorage.getItem(USER);
  return JSON.parse(user);
};

const setUser = (user) => {
  let param = JSON.stringify(user)
  localStorage.setItem(USER, param)
};

const cleanUser = () => localStorage.removeItem(USER);

export { 
  getToken, setToken, cleanToken,
  getUser, setUser, cleanUser,
}