import React, { createContext, useCallback, useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { getToken, getUser, setToken, setUser, cleanToken, cleanUser } from '../../helpers/localstoreage';
import { Http } from '../../middlewares/httpRequest'

const SessionContext = createContext({});

const SessionProvider = ({ children }) => {
  const history = useHistory();
  const [authenticatedUser, setAuthenticatedUser] = useState(() => {
    const token = getToken();
    const user = getUser();
    if (token && user) {
      return { ...user };
    }
    history.push('/login');
  })

  const signIn = useCallback(async ({ login, password }) => {
    // const http = new Http();
    // http.setResource('/session/signin')
    // http.setbody({ user: login, password })
    // const response = http.post();
    // const { token } = response.session;
    setToken('token')//token);
    setUser({})//response);
    setAuthenticatedUser({});
  }, []);

  const signOut = useCallback(() => {
    cleanToken();
    cleanUser();
    setAuthenticatedUser({});
    history.push('/login');
  }, []);

  return(
    <SessionContext.Provider
      value={{
        authenticatedUser,
        signIn,
        signOut,
      }}
    >
      { children }
    </SessionContext.Provider>
  )
}

const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new AppError('useSession must be use within an SessionProvider')
  }
  return context;
}

export {
  SessionProvider,
  useSession,
}