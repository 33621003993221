import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { SessionProvider } from './hooks/useSession';
import { CSpinner } from '@coreui/react-pro';
import './scss/style.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));

class App extends Component {
  render() {
    return (
      <Router>
        <SessionProvider>
          <Suspense fallback={<CSpinner color="primary" />}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </Suspense>
        </SessionProvider>
      </Router>
    )
  }
}

export default App;